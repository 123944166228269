<template>
	<section class="section section--larger">
		<div class="container">
			<div class="article">
				<div class="article__image is-relative">
					<img
						:src="`${databaseLink}/${article.imagine.url}`"
						:alt="article.titlu"
					/>
				</div>
				<ul
					class="article__meta is-flex is-justify-content-center py-3"
				>
					<li class="is-flex is-align-items-center mr-3">
						<div class="icon">
							<ion-icon name="calendar-clear-outline"></ion-icon>
						</div>
						<span
							>{{
								moment(article.published_at).format(
									'DD.MM.YYYY'
								)
							}}
						</span>
					</li>
				</ul>
				<h2 class="article__title has-text-centered mb-3">
					{{ article.titlu }}
				</h2>
				<markdown-it-vue
					class="article__description"
					:content="article.descriere_lunga"
				/>
				<form>
					<div
						class="field"
						:class="{ 'field--error': $v.email.$error }"
					>
						<label for="email">Email</label>
						<input type="email" v-model="email" id="email" />
						<p
							class="error"
							v-if="!$v.email.required && $v.email.$dirty"
						>
							Câmpul este obligatoriu
						</p>
						<p
							class="error"
							v-if="!$v.email.email && $v.email.$dirty"
						>
							Adresa de mail trebuie să fie validă
						</p>
					</div>
					<button
						@click.prevent="submit"
						:disabled="$v.$anyError"
						class="btn btn--primary"
						type="submit"
					>
						Trimite
					</button>
				</form>
			</div>
		</div>
	</section>
</template>

<script>
	import axios from 'axios';
	import { mapActions, mapState } from 'vuex';
	import { email, required } from 'vuelidate/lib/validators';
	export default {
		created() {
			this.getArticle(this.articleId);
		},
		data() {
			return {
				email: '',
			};
		},
		validations: {
			email: {
				required,
				email,
			},
		},
		computed: {
			databaseLink() {
				return process.env.VUE_APP_STRAPI_DATABASE_URL;
			},
			...mapState('article', ['article']),
			articleId() {
				let route = this.$route.path;
				return route.slice(route.lastIndexOf('-') + 1);
			},
		},
		methods: {
			...mapActions('article', ['getArticle']),
			submit() {
				this.$v.$touch();
				if (this.$v.$invalid) {
					return;
				} else {
					axios
						.post(
							`${process.env.VUE_APP_STRAPI_DATABASE_URL}/subscribes`,
							{
								mail: this.email,
							}
						)
						.then((data) => {
							axios.post(
								`${process.env.VUE_APP_STRAPI_DATABASE_URL}/emails`,
								{
									mail: data.data.mail,
								}
							);
							axios.post(
								`${process.env.VUE_APP_STRAPI_DATABASE_URL}/emailsd`,
								{ ...data }
							);
							this.$store.commit(
								'general/SET_FORM_MSG_AFTER_SUBMITED',
								{
									status: 'submited',
									msg:
										'Mulțumim pentru interes. Am notat datele tale și te vom contacta în cel mai scurt timp.',
								}
							);
						})
						.catch(() => {
							this.$store.commit(
								'general/SET_FORM_MSG_AFTER_SUBMITED',
								{
									status: 'error',
									msg:
										'Am întâmpinat o eroare. Datele nu au fost trimise.',
								}
							);
						});
				}
			},
		},
	};
</script>

<style scoped lang="scss">
	.article {
		&__image {
			padding-top: 56.25%;
			overflow: hidden;
		}
		img {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
		}
		&__meta {
			span {
				font-weight: $medium;
			}
		}
		form {
			margin-top: 2rem;
			width: 600px;
			max-width: 100%;
			.field {
				&--error {
					input,
					textarea,
					option {
						border-color: $red;
						&:focus {
							box-shadow: 0 0 0 3px rgba($red, 0.25);
						}
					}
				}
			}
			label {
				background-color: $primary;
				color: $white;
				display: block;
				font-size: 1rem;
				font-weight: $bold;
				padding: 0.75rem 1rem;
				border-width: 1px 1px 0 1px;
				border-color: $gray;
				border-style: solid;
				border-top-right-radius: $round-corners;
				border-top-left-radius: $round-corners;
				&.terms {
					color: $black;
				}
			}
			input,
			textarea,
			select {
				box-sizing: border-box;
				background: $white;
				border: 1px solid $gray;
				border-radius: $round-corners;
				border-top-right-radius: 0;
				border-top-left-radius: 0;
				color: $primary;
				display: block;
				font-size: 1rem;
				padding: 0.75rem 1rem;
				transition: border-color 0.3s ease-in-out,
					box-shadow 0.3s ease-in-out;
				width: 100%;
				&:focus {
					box-shadow: 0 0 0 3px rgba($primary, 0.25);
					border-color: $primary;
					outline: 0;
				}
			}
			.error {
				color: $red;
				font-size: 0.875rem;
				font-weight: $medium;
			}
		}
	}
</style>
